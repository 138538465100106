import type { ApolloError } from 'apollo-client';
import { useQuery } from 'react-apollo';

import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { usePageSpaceKey } from '@confluence/page-context';
import { getSuperAdminClaim } from '@confluence/super-admin-claim';

import type {
	SpaceRestrictionCheckQueryExperimental as TData,
	SpaceRestrictionCheckQueryExperimentalVariables as TVariables,
} from './__types__/SpaceRestrictionCheckQueryExperimental';
import { SpaceRestrictionCheckQueryExperimental } from './SpaceRestrictionCheckQueryExperimental.experimentalgraphql';

type SpaceRestrictionCheckProps = {
	asSuperAdmin?: boolean;
	children: (result: {
		error: ApolloError | undefined;
		isSpaceNotFound: boolean | undefined;
		isSpaceRestricted: boolean | undefined;
		loading: boolean;
	}) => JSX.Element;
	spaceKey: string;
};

export function SpaceRestrictionCheck({
	asSuperAdmin,
	children,
	spaceKey,
}: SpaceRestrictionCheckProps) {
	const superAdminClaim = getSuperAdminClaim();

	const [stateSpaceKey] = usePageSpaceKey();
	const { data, error, loading } = useQuery<TData, TVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpaceRestrictionCheckQueryExperimental,
		{
			context: asSuperAdmin == undefined ? undefined : { asSuperAdmin },
			fetchPolicy: superAdminClaim ? 'no-cache' : undefined,
			variables: {
				// XXX Though SpaceRestrictionCheckProps defines spaceKey as required,
				// SpaceBaseComponent is not written in TypeScript and it may provide
				// undefined as it is reading spaceKey from redux. While it would result
				// in a better separation of concerns to have a fix in SpaceBaseComponent,
				// such a fix would be riskier.
				spaceKey: spaceKey || stateSpaceKey!,
			},
		},
	);

	let isErrorHandled: boolean | undefined;
	let isSpaceNotFound: boolean | undefined;
	let isSpaceRestricted: boolean | undefined;
	if (error) {
		isSpaceNotFound = isSpaceNotFoundError(error);
		isSpaceRestricted = isSpaceRestrictedError(error);
		if (isSpaceNotFound || isSpaceRestricted) {
			markErrorAsHandled(error);
			isErrorHandled = true;
		}
	} else if (data) {
		const statusCode = data?.experimentalSpace?.statusCode;
		// SPACEK_KEY yields a 400 error not 404
		isSpaceNotFound = statusCode === 404 || statusCode === 400;
		isSpaceRestricted = statusCode === 403;
	}

	return children({
		error: isErrorHandled ? undefined : error,
		isSpaceNotFound,
		isSpaceRestricted,
		loading,
	});
}

export function isSpaceNotFoundError(error: ApolloError) {
	return isSpaceError(error, ({ statusCode }) => statusCode === 404 || statusCode === 400);
}

export function isSpaceRestrictedError(error: ApolloError) {
	return isSpaceError(error, () => isUnauthorizedError(error));
}

function isSpaceError(
	{ graphQLErrors }: ApolloError,
	extensionsPredicate: (extensions: {
		data?: { authorized?: boolean };
		statusCode?: number;
	}) => boolean,
) {
	if (graphQLErrors && graphQLErrors.length > 0) {
		for (const { extensions, path } of graphQLErrors) {
			if (
				Array.isArray(path) &&
				path.indexOf('space') !== -1 &&
				extensions &&
				extensionsPredicate(extensions)
			) {
				return true;
			}
		}
	}
	return false;
}
