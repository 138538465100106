import type { FC } from 'react';
import React, { Fragment } from 'react';

import { LoadableAfterPaint, LoadableHydrateOnHover } from '@confluence/loadable';
import { checkSSRPartialSuccess, SSR_PARTIAL_COMPONENT } from '@confluence/ssr-utilities';
import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';

import type { PageTreeProps } from './PageTree';
export { ConditionalTreeItemTooltip } from './ConditionalTreeItemTooltip';
export { getI18nMessageForKnownError } from './movePublishedPageErrorHelpers';

export type { ContentTreeItem } from './data-transformers';

export const ANIMATE_TIME_SEC = 2;
const DELAY_HYRATION_ON_HOVER_FF = 'confluence_frontend_delay_hydration_on_hover';

export const PageTreeLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-PageTree" */ './PageTree')).PageTree,
});

export const PageTreeLoaderOnHover = LoadableHydrateOnHover({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-PageTreeOnHover" */ './PageTree')).PageTree,
	hydrateImmediately: () => {
		// If page tree was not successfully rendered on server-side, hydrate immediately otherwise user will see a blank page tree
		return !checkSSRPartialSuccess(SSR_PARTIAL_COMPONENT.PageTree);
	},
});

export const PageTree: FC<PageTreeProps> = (props) => {
	const delayHydrationOnHoverTimeout =
		StatsigConfigurations.getNumber(DELAY_HYRATION_ON_HOVER_FF, 0) || 0;
	return (
		<Fragment>
			{delayHydrationOnHoverTimeout > 0 ? (
				<PageTreeLoaderOnHover {...props} />
			) : (
				<PageTreeLoader {...props} />
			)}
		</Fragment>
	);
};
